<template> 

 <CRow class="mt-5 row justify-content-md-center" >
    <CCol md="2"><div class="ml-2"><h4><strong >我的報表</strong></h4><h6>檢視您的歷史報表資訊 <small> (報表有效時間已過期將不在此列)</small></h6></div></CCol>
    <CCol md="9">
         <div class="card" id="myReportTop" >
                <CCardHeader><CIcon name="cil-list" height="20" /><span class="h6"  > Reports History 歷史報表</span></CCardHeader>
                <div class="card-body" style="padding: 5px;">  
                    <div class="sm-table-container">
                        <vueTable2   :apiUrl="reportsApiUrl" :fieldsDef="reportsFieldsDef"></vueTable2>  
                    </div> 
                </div> 
        </div> 
                  
           
  </CCol>  
</CRow>   
</template>

<script>
import Vue from "vue";
import vueTable2 from "../../components/DisplayTemplate/vueTable2.vue"
import appSettings from '../../util/settings'; 
import store from '../../util/store';
import mapfunction from '../../util/mapfunction' 
import MyReportsVuetableField from '../../views/reports/MyReportsVuetableField.vue'
//import { cilOptionsHorizontal } from 'c:/Users/Karen/Downloads/coreui-icons-master/coreui-icons-master';

let pageSize = appSettings.PerPage;

//改用componet方式實作td的內容
let fields = [
    {
        name:MyReportsVuetableField,
        title:'報告資訊'
    }
];

/*
let fields = [
    {
      name: 'Id',
      title: '位置',
      width: "60%",
      formatter: value => {
        return "<div id='mapResult"+value+"' style='height: 150px; width:100%;'></div>";
      },
    },
    {
        name: '',
        title: '報告資訊',
        formatter: value => {
            var showText = "<div>報告時間：" + value.ReportDate + "</div>";
            showText+= "<div>產業別：" + value.Industry + "</div>";
            showText+= "<div>服務半徑：" + value.Radius + "</div>";
            showText+= "<div>有效時間：<strong style='background-color:yellow'>" + value.ValidReportDateTime + "</strong></div>";
            showText+= "<div><input type='button' value='顯示' onclick='location.href=\"/ShowReport?id="+ value.Id +"\"' /></div>";
            return showText;
        },
     }
];
*/

export default {
    
    components: {  
        vueTable2,
         
    },
    data() {
        return {
            reportsApiUrl: appSettings.GetReports +"?userToken=" + encodeURIComponent(store.state.token) + "&perPage=" +pageSize, 
            reportsFieldsDef: fields,

            isLoading:false,
             
        }
    } 
}
</script>