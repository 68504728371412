<template>
  <th v-if="isHeader" v-html="title"></th> 
  <td v-else class="mb-2 mt-2"  @click="showReport(rowData.id)"> 
  <div>
     <CRow>
        <CCol md="6">  
            <div class="text-center"><h6 style="color:#3c4b64"><CIcon name="cil-location-pin" :height="20" /> {{rowData.TargetArea}}</h6></div>
            <div :id="'mapResult'+ rowData.id" style='height: 120px; width:100%;pointer-events:none;'></div>  
        </CCol>
        <CCol>
            <CRow class="mt-2">
                <CCol>
                    <span class="text-muted">報告時間：</span>{{rowData.ReportDate}}
                    <!--<div class="pull-right"><svg-img-icons name="openes" width="20" height="20" /></div>-->
                </CCol>
            </CRow>
            <CRow>
                <CCol><span class="text-muted">產業別：</span>{{rowData.Industry}}</CCol>
            </CRow>
            <CRow>
                <CCol><span class="text-muted">服務半徑：</span>{{rowData.Radius}}</CCol>
            </CRow>
            <CRow>
                <CCol><span class="text-muted">預期平均客單價：</span>{{getPriceRange(rowData.Price)}}</CCol>
            </CRow> 
            <CRow>
                <CCol><span class="text-muted">有效時間：</span><strong class="text-danger">{{rowData.ValidReportDateTime}}</strong></CCol>
            </CRow>
        </CCol>
     </CRow> 
  </div> 
  </td> 
</template>

<script>
    import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue'
    import mapfunction from '../../util/mapfunction'
    import appSettings from '../../util/settings';

    const PRICE_LIST = appSettings.DropDownList.PriceList;

    export default {
        name: 'my-reports-vuetable-field',
        mixins: [VuetableFieldMixin], 
        methods: {
            showReport: function (id) {
                //報告可以回到上一頁
                //location.href="/MarketReportResult?id="+ id
                //報告不可以回到上一頁
                location.href = "/ShowReport?id=" + id
                // showText+= "<div><input type='button' value='顯示' onclick='location.href=\"/ShowReport?id="+ value.Id +"\"' /></div>";
            },
            setReportMap: function (data) {
                if (data !== null) {
                    let id = 'mapResult' + data.id;
                    let x = data.X;
                    let y = data.Y;

                    document.getElementById(id).innerHTML = "";

                    let bingMapsKey = "";
                    let mapBoxKey = "";

                    let mapFactory = mapfunction.createMapFactory(bingMapsKey, mapBoxKey);

                    let map = mapFactory.createMap(id,
                        "",
                        {
                            zoomTipLabel: { zoomInTipLabel: 'ZoomIn', zoomOutTipLabel: 'ZoomOut' },
                            zoomValue: {
                                default: 15,
                                minZoom: 15,
                                maxZoom: 15
                            },
                            center: [x, y],
                            disableControlls: true
                        });

                    map.setBaseMap(mapfunction.MapStyle.GoogleMapRoad, false, "OverviewMap");
                    map.createScaleLine();
                    map.setZoom(17);

                    map.createPoint("pp", x, y, "#ff143f");
                }

            },
            getPriceRange: function (priceKey) {
               
                var find = PRICE_LIST.find(x => x.value == priceKey);
                if (find !== undefined)
                    return find.label;
            }
        },
        mounted: function () {
            //console.log("id:"+this.rowData.id);
            this.setReportMap(this.rowData);
        },
        updated: function () {
            this.setReportMap(this.rowData);
        }
    }
</script>